import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Forms {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Forms',
    subtitle: `Your regularly occurring records and forms can pile up quickly and turn an organized desk into a mountain of headaches. Conquer your workflow by submitting documents electronically via email, fax, or uploading into our app. Approve and manage submissions in the Administrator Portal. Relish in your newfound desk-space and rediscover your kids’ school picture.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: "/features/forms/forms-banner.png"
  }

  const seo = {
    title: 'Workhub | Free Form Management Software Submit Online',
    description: 'Create forms from templates, link to online forms, assign them to locations & submit electronically via email, fax or uploading to the Workhub app.',
    image: '/features/forms/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Templating",
      para: "Upload a template for each form type, or link to an online form and specific submission frequencies.",
    },
    {
      title: "Identify & Assign",
      para: "If a submission reveals that work is needed, an admin can assign an action item. The form it was created in is automatically linked providing context for workers. ",
    },
    {
      title: "Notifications",
      para: "Workers who have been assigned tasks receive notifications via SMS (Text) or Email. ",
    },
    {
      title: "Submit Virtually",
      para: "Workers can submit forms by email, fax, web or on their smartphone. Click on the form or web link to fill it out and submit.",
    },

  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">

        

   

      <section className="feature-section">
        <div className="container" >
          <div className="row switch-children">

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInLeft" delay="0.9s">
              <img 
              className="feature-img-right" 
              src="\features\forms\forms-cartoon.png"
              alt="forms cartoon"
               /></ReactWOW>

            </div>


            <div className="col-lg-6 feature-text">
            <h2>Move Those Filing Cabinets Online.</h2>
            <p>Upload a template for each form type or link to an online form to start your workers off on the right foot. You can assign them to Locations or Assets and specify a frequency (twice a week, or once a month etc.) so you know when form submissions aren’t conforming (hehe, pun intended). The combination of assignment and frequency allows administrators to keep track of which documents are being submitted on time, and which are past due. It’s also easy to ensure proper submission of high priority documents by enabling an approval process.</p>
            </div>
          </div>
        </div>
      </section>


      <section className="feature-section">
        <div className="container" >
          <div className="row">

        
            <div className="col-lg-6 feature-text">


              <h2> Submit Virtually.</h2>

              <p>Save filing and processing time by submitting your forms online. Workers can submit forms in a variety of ways including email, fax, web, or on their smartphone. They can click on the form or web link, fill it out, submit, and they’re done. Workers can also be incentivized by rewarding them with points; great for that office checklist no one wants to do!.</p>

            </div>

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInRight" delay="0.9s">

              <img 
              className="feature-img-left" 
              src="\features\forms\forms-tablet.png"
              alt="forms admin computer view"
                 />

                </ReactWOW>

            </div>

          </div>
        </div>
      </section>



      <section className="feature-section">
        <div className="container" >
          <div className="row switch-children">

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInLeft" delay="0.9s">

              <img 
            className="feature-img-right"
              src="\features\forms\forms-admin.png"
                />

                </ReactWOW>

            </div>


            <div className="col-lg-6 feature-text">

              <h2>And…Action!</h2>

              <p>If a submitted form reveals work needed, an administrator can assign an action item. The action item contains an explanation of a deficiency identified and a corrective action needed. If the worker needs more context, the form it was created in is automatically linked, allowing for easy access. Notify the worker who needs to complete the job via SMS or Email, then sit back and relax!</p>


            </div>
          </div>
        </div>
      </section>

      <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>

    </Layout>
  )
}